<template>
    <div class="form-container">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(askContact)" v-if="!loading">
                <div class="form-row">
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:50" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}" placeholder="Prénom*" v-model="firstname" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:50" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  placeholder="Nom*" v-model="lastname" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:50" v-slot="{ errors }">
                            <input type="text" placeholder="Société" :class="{ 'input-error' : errors[0]}" v-model="company" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:50" v-slot="{ errors }">
                            <input type="text" placeholder="Fonction" :class="{ 'input-error' : errors[0]}" v-model="job" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
        
                </div>   
                <div class="form-row">
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:50|required" v-slot="{ errors }">
                            <input type="text" placeholder="Ville*" :class="{ 'input-error' : errors[0]}" v-model="city" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                         <ValidationProvider  class="input-container"  rules="max:50" v-slot="{ errors }">
                            <input type="text" placeholder="Pays" :class="{ 'input-error' : errors[0]}" v-model="country" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
        
                </div> 
                <div class="form-row">
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="max:50" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}" placeholder="Téléphone" v-model="phone" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="form-col">
                        <ValidationProvider  class="input-container"  rules="required|max:50|email" v-slot="{ errors }">
                            <input type="text" :class="{ 'input-error' : errors[0]}"  placeholder="Email*" v-model="email" />
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div> 
                <div class="form-row">
                    <ValidationProvider  class="input-container"  rules="max:500|required" v-slot="{ errors }">
                        <textarea v-model="message" :class="{ 'input-error' : errors[0]}" placeholder="Message*" rows="8"></textarea>
                        <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-row form-submit">
                    <input type="submit" class="btn btn-gradiant" value="envoyer" />
                </div>
                <div class="notif success" v-if="succes">
                    <p>Votre demande a bien été envoyée.<br />Vous allez être contacté par nos équipes très prochainement.</p>
                </div>
                <div v-if="error" class="notif error">
                    <p>{{error}}</p>
                </div>
            </form>
            <div v-else class="loader-container">
                <Loader></Loader>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import Loader from './Loader'
import { ValidationProvider,  ValidationObserver } from 'vee-validate'
import axios from 'axios'
import store from './../store'

export default {
    name : "FormContact",
    components : {
        ValidationProvider,
        ValidationObserver,
        Loader
    },
    data() {
        return {
            firstname : null,
            lastname : null,
            company : null,
            phone : null,
            email : null,
            city : null,
            country : null,
            job : null,
            message: null,
            error : null,
            succes : false,
            loading: false
        }
    },
    methods : {
        askContact() {
            this.loading = true
            this.error = null
            this.succes = false

            let formData = new FormData();

            formData.append('firstname', this.firstname)
            formData.append('lastname', this.lastname)
            formData.append('email', this.email)
            formData.append('message', this.message)
            formData.append('city', this.city)
            if(this.phone) {
                formData.append('phone', this.phone)
            }            
            if(this.company) {
                formData.append('company', this.company)
            }
            if(this.country) {
                formData.append('country', this.country)
            }
            if(this.job) {
                formData.append('job', this.job)
            }

            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .post(store.state.URL + '/home_contact', formData, config)
                .then(function (res) {
                    vm.loading = false
                    if (res.status == 400) {
                        vm.error = 'Veuillez remplir tous les champs obligatoire'
                    }else if(res.status == 500) {
                        vm.error = 'Une erreur serveur est survenue, veuillez réessayer dans un moment'
                    }else if(res.status == 200) {
                        vm.succes = true
                        vm.firstname = null
                        vm.lastname = null
                        vm.company = null
                        vm.phone = null
                        vm.email = null
                        vm.message = null
                        vm.city = null
                        vm.country = null
                        vm.job = null
                    }else {
                        vm.error = 'Une erreur inattendue est survenue'
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-container, .form-container span:first-of-type {
        display: flex;
        width: 100%;
        flex-direction: column; 
    }
    
    form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .form-row {
        width:100%;
        display: flex;
        justify-content: center;
        margin:10px 0;
    }

    .form-col {
        width:50%;
        display: flex;
        justify-content: center;
    }

    input, textarea {
        background:none;
        color:#fff;
        padding:12px 20px;
        font-size:18px;
        font-weight:300;
        border-radius:20px;
        border: 2px solid #fff;
        //width:calc(100% - 40px);
        margin:0 10px;
        font-family: $fontRegular;
    }

    input:focus, textarea:focus {
        outline: none;
    }

    input::placeholder, textarea::placeholder {
        color:#fff;
    }

    .input-container {
        flex-direction: column;
    }

    .input-container span {
        color:$error;
        font-size:12px;
        margin-left: 30px;
    }

    input.input-error, textarea.input-error {
        border-color:$error;
    }

    input.input-error::placeholder, textarea.input-error::placeholder {
        color:$error;
    }

    @media screen and (max-width: 768px) {
        .form-col {
            width:100%;            
        }

        .form-row:not(.form-submit) {
            flex-direction: column;
            margin: 0;
        }

        .input-container {
            margin:10px 0;
        }
    }


</style>