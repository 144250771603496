<template>
    <div class="main">
        <div class="banner">
            <div class="banner-overlay"></div>
             <h1>CONTACT</h1>
        </div>
        <section class="contact-container">
            <div class="contact-content">
                <h2>VOUS AVEZ UNE QUESTION ?</h2>                          
                <h3>Complétez ce formulaire !</h3>
                <p>L'un des membres de notre équipe vous contactera très prochainement !</p>
                <div class="form-component">
                    <FormContact></FormContact> 
                </div>    
            </div>  
        </section>
    </div>
    
</template>


<script>
import FormContact from './../components/FormContact'

export default {
    name : 'Contact',
    components : {
        FormContact
    }
}
</script>

<style lang="scss" scoped>
    .banner {
        background-image : url('./../assets/bg-about.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height:300px;
        min-height: auto;
    }

    .contact-title {
        display: flex;
        justify-content: center;
        padding-top:40px;
        padding-bottom:40px;
    }

    .form-component {
        margin-top:40px;
        width: 100%;
        max-width: 800px;
    }


    .contact-container {
        padding:40px 0 80px 0;
        display: flex;
        flex-direction: column;
        align-items:center;
        background-image: url('./../assets/bg-stands.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .contact-content{
        width: $boxWidth;
        max-width: 96%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

   

    .contact-container h2, .contact-container h3, .contact-container p {
        color:#fff;
    }

    .contact-container h3 {
        font-size:25px;
        font-weight: 400;
    }

    @media screen and (max-width: 768px) {
        .row {
            max-width: 90%;
            margin:0 5%;
        }

        p {
            text-align: center;
        }
    }
</style>